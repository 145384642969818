<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import Spinner from "vue-simple-spinner";
import * as cloneDeep from "lodash.clonedeep";
import { required, email } from 'vuelidate/lib/validators';
export const CHAIPAY_APP_URL = process.env.VUE_APP_CHAIPAY_FRONTEND_URL;
import PerPage from '../../../components/per-page.vue';
import Filters from '../../../components/filters.vue';
import * as moment from "moment";
import switchInput from '../../../components/switch-input.vue';

import { paymentMethods } from "@/state/helpers";
import {
  FETCH_MERCHANTS_PAGINATED_LIST,
  FETCH_INVITED_MERCHANTS_LIST,
  ACTIVE_INACTIVE_MERCHANT,
  INVITE_MERCHANT,
  MERCHANT_RESEND_KYC,
  MERCHANT_RESEND_INVITE,
  DELETE_MERCHANT
} from "@/state/actions.type";
import Common from "../../../helpers/Common";

export default {
  components: {
    Layout,
    // PageHeader,
    Spinner,
    PerPage,
    Filters,
    switchInput
  },
  data() {
    return {
      merchant_name:"",
      merchant_mail_id:"",
      merchant_country: "VN",
      countryList: Common.countryList(),
      // merchant_phone_number:"",
      // AccountStatus:"",
      isInviteSend:false,
      AccountType:"MANAGED",
      isLoginAllowed: true,
      isInviteMerchant: false,
      useMasterMerchantCreds: false,
      sendKycLinkEmail: false,
      merchantListData: [],
      invitedmerchantListData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      mor_filter: "",
      filter: {},
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      showOptions: false,
      fromDate: "",
      toDate: "",
      viewDetailsModalOpen:false,
      viewDetailsData:{},
      deteleMerchantData:{},
      fields: [
        { key: "status", sortable: true, label: "views.master_merchant.table.fields.status" },
        { key: "name", sortable: true, label: "views.master_merchant.table.fields.name" },
        { key: "key", sortable: false, label: "views.master_merchant.table.fields.key" },
        { key: "email_address", sortable: true, label: "views.master_merchant.table.fields.email_id" },
        // { key: "ph_number", sortable: true, label: "Phone Number" },
        // { key: "is_invite_merchant", sortable: false, label: "views.master_merchant.table.fields.invite_merchant" },
        { key: "is_login_allowed", sortable: false, label: "views.master_merchant.table.fields.login_allowed" },
        { key: "use_master_merchant_creds", sortable: false, label: "views.master_merchant.table.fields.use_mm_creds" },
        { key: "show_details", sortable: false, label: '' },
      ],
      invitedfields: [
        { key: "status", sortable: true, label: "views.master_merchant.table.fields.status" },
        { key: "email_id", sortable: true, label: "views.master_merchant.table.fields.email_id" },
        { key: "created_at", sortable: true, label: "views.master_merchant.table.fields.date" },
        { key: "invite_link", sortable: true, label: "Invite link" },
        { key: "resend_invite_email", sortable: false, label: "Resend Invite Email" },
      ],
      submitted: false
    };
  },
  validations: {
    merchant_name: {
      required
    },
    merchant_mail_id: {
      required,
      email
    }
  },
  created() {
    // this.fetchMerchants()
    this.merchantListData = cloneDeep(this.merchantListDataFromStore);
    this.$store.dispatch(`masterMerchant/${FETCH_INVITED_MERCHANTS_LIST}`);
    this.invitedmerchantListData = cloneDeep(this.invitedmerchantListDataFromStore);
  },

  computed: {
    isValid() {
      if (this.merchant_name && this.merchant_mail_id) {
        return true;
      } else {
        return false;
      }
    },
    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.paginatedContent;
    },

    invitedmerchantListDataFromStore() {
      return this.$store.state.masterMerchant.invitedContent;
    },

    isFetchingTransactions() {
      return this.$store.state.masterMerchant.isFetchingData;
    },
    
    rows() {
      return this.$store.state.masterMerchant.total_elements;
    },

    // items() {
    //   return [
    //     {
    //       text: "PortOne",
    //       active: true,
    //     },
    //     {
    //       text: this.$t('menuitems.master_merchant.text'),
    //       active: false,
    //     },
    //   ];
    // }
  },
  watch: {
    // perPage: function (val) {
    //   this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
    //     pageIndex: this.currentPage,
    //     pagesize: val,
    //     filters: this.filter
    //   });
    // },

    // currentPage: function (val) {
    //   this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
    //     pageIndex: val,
    //     pagesize: this.perPage,
    //     filters: this.filter,
    //   });
    // },

    merchantListDataFromStore() {
      this.merchantListData = cloneDeep(this.merchantListDataFromStore);
    },

    invitedmerchantListDataFromStore() {
      this.invitedmerchantListData = cloneDeep(this.invitedmerchantListDataFromStore);
    },
  },

  mounted() {
    // Set the initial number of items
    this.merchantListData = cloneDeep(this.merchantListDataFromStore);
    this.invitedmerchantListData = cloneDeep(this.invitedmerchantListDataFromStore);
    this.totalRows = this.merchantListData.length;
  },

  destroyed() {
    // document.body.classList.remove("dynamic-body");
  },

  methods: {
    ...paymentMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    sortByDate(a, b, key) {
      if (key == "date") {
        const d1 = new Date(a[key]).getTime();
        const d2 = new Date(b[key]).getTime();
        return d1 - d2;
      }
    },

    filterTransactionByMor() {
      if (this.mor_filter == "") {
         this.fetchMerchants()
      } else {
         this.fetchMerchants()
      }
    },
    InviteMerchant() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.isInviteSend = true;
      this.submitted = false;
      this.$store.dispatch(`masterMerchant/${INVITE_MERCHANT}`,{
        email_address: this.merchant_mail_id,
        name:this.merchant_name,
        country: this.merchant_country,
        is_login_allowed: this.isLoginAllowed,
        is_invite_merchant: this.isLoginAllowed,
        use_master_merchant_creds: this.useMasterMerchantCreds,
        send_kyc_link_email: this.sendKycLinkEmail
      }).then(() => {
        this.isInviteSend = false;
        this.hideInviteMerchantModal()
        this.$notify({
          type: "success",
          text: this.$t('views.master_merchant.merchant_added_success'),
          closeOnClick: true,
        });
        this.fetchMerchants();
        this.$store.dispatch(`masterMerchant/${FETCH_INVITED_MERCHANTS_LIST}`);
      })
      .catch((error) => {
        // error
        this.hideInviteMerchantModal()
        const errorObject = JSON.parse(error.message)
        console.log("error", error);
        this.isInviteSend = false;
        this.$notify({
          type: "error",
          text: errorObject.message,
          closeOnClick: true,
        });
      });

      // if(this.AccountType == "MANAGED"){
      //  this.$store.dispatch(`masterMerchant/${INVITE_MERCHANT_FOR_MANAGED}`,{
      //     email: this.merchant_mail_id,
      //     merchant_type:this.AccountType
      //   }).then(() => {
      //     this.isInviteSend = false;
      //     this.showInviteMerchantmodal = false;
      //     this.$notify({
      //       type: "success",
      //       text: "Merchant Invited Successfully.",
      //       closeOnClick: true,
      //     });
      //     this.$store.dispatch(`masterMerchant/${FETCH_INVITED_MERCHANTS_LIST}`);
      //     this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
      //       pageIndex: 1,
      //       pagesize: 10,
      //       filters: this.filter,
      //     });
      //   })
      //   .catch((error) => {
      //     // error
      //     this.showInviteMerchantmodal = false;
      //     console.log("error", error);
      //   });
      // }else{
      //     this.$store.dispatch(`masterMerchant/${INVITE_MERCHANT_FOR_OWNED}`,{
      //       email: this.merchant_mail_id,
      //       merchant_type:this.AccountType,
      //       name:this.merchant_name,
      //     }).then(() => {
      //       this.isInviteSend = false;
      //       this.showInviteMerchantmodal = false;
      //       this.$notify({
      //         type: "success",
      //         text: "Merchant Added Successfully.",
      //         closeOnClick: true,
      //       });
      //       this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
      //         pageIndex: 1,
      //         pagesize: 10,
      //         filters: this.filter,
      //       });
      //     })
      //     .catch((error) => {
      //       // error
      //       this.showInviteMerchantmodal = false;
      //       console.log("error", error);
      //     });
      // }
    },
    fetchTransactionOnEmptyFilter() {
      if (this.mor_filter == "") {
         this.fetchMerchants()
      }
    },
    openInviteMerchantModal() {
      this.merchant_name = ""
      this.merchant_mail_id = ""
      this.isLoginAllowed = true
      this.isInviteMerchant = false
      this.useMasterMerchantCreds = false
      this.sendKycLinkEmail = false
      // this.merchant_phone_number = ""
      // this.AccountStatus = ""
      this.AccountType = "MANAGED"
      this.$bvModal.show('showInviteMerchant');
    },

    hideInviteMerchantModal() {
      this.$bvModal.hide('showInviteMerchant');
    },
    markasActiveandInactive(val,uuid) {
      this.$store.dispatch(`masterMerchant/${ACTIVE_INACTIVE_MERCHANT}`, {
        status: val,
        uuid:uuid
      }).then(() => {
         this.closeViewDetailsModal()
         this.fetchMerchants()
      });
    },
    onCopySuccess() {
      this.$notify({
        type: "success",
        text: this.$t('views.master_merchant.kyc_link_copy_success'),
        closeOnClick: true,
      });
    },
    onCopySuccess1() {
      this.$notify({
        type: "success",
        text: 'Invite link copied to clipboard.',
        closeOnClick: true,
      });
    },
    getRegionName(countryCode) {
      let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
      return regionNames.of(countryCode);
    },
    resendKYC(item) {
      this.$store.dispatch(`masterMerchant/${MERCHANT_RESEND_KYC}`, {
        iamportKey: item.key
      }).then(() => {
        this.$notify({
          type: "success",
          text: this.$t('views.master_merchant.kyc_link_sent_success'),
          closeOnClick: true,
        });
      });
    },
    resendInvite(item) {
      this.$store.dispatch(`masterMerchant/${MERCHANT_RESEND_INVITE}`, {
        email: item.email_id
      }).then(() => {
        this.$notify({
          type: "success",
          text: "Invite Link sent successfully.",
          closeOnClick: true,
        });
      });
    },
    deleteMerchant(item) {      
       this.$store.dispatch(`masterMerchant/${DELETE_MERCHANT}`, {
            merchantKey: item.key
          })
          .then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              text: this.$t("views.master_merchant.merchant_delete_success"),
              closeOnClick: true,
            });
          })
          .catch((error) => {
            // error
            console.log("error", error);
            this.$refs['delete-merchant-modal'].hide();
            this.$notify({
              type: "success",
              text: this.$t("views.master_merchant.master_merchant_delete_failed"),
              closeOnClick: true,
            });
          })
          .finally(() => {
            this.$refs['delete-merchant-modal'].hide();
            this.fetchMerchants();
            this.merchantListData = cloneDeep(this.merchantListDataFromStore);
          });

    },
    fetchMerchants() {
      const payload = {
        page: this.currentPage,
        pagesize: this.perPage,
        filters: this.filters,
        sorting_data: this.sorting_data
      };

      if(this.fromDate) {
        payload.from = moment(this.fromDate, 'DD-MM-YYYY HH:mm').format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        payload.from = moment('2018-01-01T00:00:00.000').format("YYYY-MM-DDTHH:mm:ssZ");
      }

      if(this.toDate) {
        payload.to = moment(this.toDate, 'DD-MM-YYYY HH:mm').format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        payload.to = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      }

      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
        pageIndex: this.currentPage,
        pagesize: this.perPage,
        filters: this.filter,
        from:payload.from,
        to:payload.to
      });
    },
    dateChanged(value) {
      this.fromDate = value[0];
      this.toDate = value[1];

      this.fetchMerchants();
    },
    closeViewDetailsModal() {
      this.viewDetailsModalOpen = false;
    },
    viewDetailsClick(data){
      this.viewDetailsData = data
      this.viewDetailsModalOpen = true
    },
    showDeleteMerchantModal(val){
        this.deteleMerchantData = val;
        this.$refs['delete-merchant-modal'].show();
    },
    getFirstLetter(val){
        let sentence = val;
        let words = sentence.split(" ");
        let initials = words.map(word => word[0]).join("");
        return initials 
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader title="Master Merchant" :items="items" /> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="row d-flex justify-content-end align-items-start">
          <button
            @click="openInviteMerchantModal"
            class="btn btn-primary btn-sm mr-3"
            style="padding: 12px 16px;font-weight: 400;font-size: 14px;line-height: 20px;"
          >
          <span style="padding-right: 10px;">+</span> {{ $t('views.master_merchant.add_merchant') }}
          </button>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-md-12">
            <filters class="mb-3" @date-change="dateChanged" hideFilter />
              <!-- <b-input-group class="d-inline-flex align-items-center input-group col-lg-5">
                <b-form-input
                  type="search"
                  v-model="mor_filter"
                  @keyup="fetchTransactionOnEmptyFilter"
                  @keyup.enter="filterTransactionByMor"
                  class="form-control form-control-sm ml-2"
                  placeholder="Search"
                  style="border: 1px solid #EAEAEA;"
                ></b-form-input>
                <b-input-group-append>
                  <button
                    class="btn btn-primary btn-block btn-sm search-button"
                    @click="filterTransactionByMor"
                  >
                    {{ $t("views.payments.transactions.search") }}
                  </button>
                </b-input-group-append>
              </b-input-group> -->
          </div>
        </div>

        <div class="">
          <b-table
            class="table-custom text-left table-centered"
            :items="merchantListData"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-compare="sortByDate"
            :small="true"
            :filter-included-fields="filterOn"
            sticky-header="400px"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >
          <template v-slot:head()="data">
            {{ $t(data.label) }}
          </template>
          <template v-slot:cell(status)="row">
              <div
                class="badge font-size-12"
                style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                :class="{
                  'badge-soft-inactive':`${row.value}` === 'INACTIVE',
                  'badge-soft-success': `${row.value}` === 'ACTIVE',
                }"
              >
                {{ row.value }}
              </div>
            </template>
            <template v-slot:cell(is_login_allowed)="row">
              <!-- <img src="@/assets/images/check-circle.svg" v-if="row.value">
              <img src="@/assets/images/x-circle.svg" v-else> -->
               <switch-input class="ml-auto toggle-in-table" disableToggle :value="row.value" />
            </template>
            <template v-slot:cell(use_master_merchant_creds)="row">
              <!-- <img src="@/assets/images/check-circle.svg" v-if="row.value">
              <img src="@/assets/images/x-circle.svg" v-else> -->
              <switch-input class="ml-auto toggle-in-table" disableToggle :value="row.value" />
            </template>
            <template #cell(show_details)="row">
                <div style="display: flex;align-items: center;gap: 10px; cursor: pointer;">
                    <b-dropdown class="show_details" size="sm" variant="white" dropleft>
                            <template v-slot:button-content>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <g id="Iconsax/Linear/more">
                                <path id="Vector" d="M6.6665 12.6667C6.6665 13.4 7.2665 14 7.99984 14C8.73317 14 9.33317 13.4 9.33317 12.6667C9.33317 11.9333 8.73317 11.3333 7.99984 11.3333C7.2665 11.3333 6.6665 11.9333 6.6665 12.6667ZM6.6665 3.33333C6.6665 4.06667 7.2665 4.66667 7.99984 4.66667C8.73317 4.66667 9.33317 4.06667 9.33317 3.33333C9.33317 2.6 8.73317 2 7.99984 2C7.2665 2 6.6665 2.6 6.6665 3.33333ZM6.6665 8C6.6665 8.73333 7.2665 9.33333 7.99984 9.33333C8.73317 9.33333 9.33317 8.73333 9.33317 8C9.33317 7.26667 8.73317 6.66667 7.99984 6.66667C7.2665 6.66667 6.6665 7.26667 6.6665 8Z" stroke="black" stroke-opacity="0.3"/>
                                </g>
                            </svg>
                            </template>
                          <b-dropdown-item-button @click="viewDetailsClick(row.item)" >View Details</b-dropdown-item-button>
                        <b-dropdown-item-button @click="showDeleteMerchantModal(row.item)"> 
                            {{ $t("button.delete") }}
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </template>


          </b-table>
          <Spinner v-if="isFetchingTransactions === true"></Spinner>
            <div class="d-flex justify-content-between mb-3">
              <!-- pagination -->
                <!-- <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  style="border: 1px solid #EAEAEA;"
                ></b-form-select> -->


              
              <per-page
                v-model="perPage"
                :currentPage="currentPage"
                :totalRows="rows"
                :tableRowsCount="merchantListData.length"
                @input="fetchMerchants"
              />
              <b-pagination v-model="currentPage" :current-page="currentPage" :total-rows="rows" :per-page="perPage" @input="fetchMerchants"></b-pagination>
          </div>


        </div>
        <!-- <div class="row">
          <div class="col">
            <div
              class="dataTables_paginate paging_simple_numbers float-right"
              style="padding-bottom: 20px;"
            >
              <ul class="pagination pagination-rounded mb-0"> -->
                <!-- pagination -->
                <!-- <b-pagination
                  v-model="currentPage"
                  :current-page="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
            <div class="clear-both"></div>
          </div>
        </div> -->
        <!-- </b-tab> -->
        <div data-v-61538448="" style="color: rgb(37, 43, 59); padding-bottom: 15px;">{{ $t('views.master_merchant.invited_merchant') }}</div>
        <div class="table-responsive">
          <b-table
            class="table-centered text-center table-custom"
            :items="invitedmerchantListData"
            :fields="invitedfields"
            responsive="sm"
            :bordered="false"
            :small="true"
            sticky-header="400px"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >
            <template v-slot:head()="data">
              {{ $t(data.label) }}
            </template>
            <template v-slot:cell(status)="row">
              <div
                class="badge font-size-12"
                style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                :class="{
                  'badge-soft-inactive':`${row.value}` === 'INACTIVE',
                  'badge-soft-success': `${row.value}` === 'ACTIVE',
                  'badge-soft-info': `${row.value}` === 'INVITED'
                }"
              >
                {{ row.value }}
              </div>
            </template>
            <template v-slot:cell(invite_link)="row">
              <a v-show="row.value" target="_black" :href="row.value">
                {{ $t("View Link") }}</a
              >
              <span v-show="!row.value"> NA</span>
              <i
                v-show="row.value"
                v-clipboard:copy="row.value"
                v-clipboard:success="onCopySuccess1"
                class="mdi mdi-content-copy btn-lg btn"
                title="Copy"
                v-b-tooltip.hover.right
              ></i>
            </template>
            <template v-slot:cell(resend_invite_email)="row">
              <b-btn style="border-radius: 8px;" size="sm" @click="resendInvite(row.item)" variant="primary">Resend Invite</b-btn>
              <!-- <b-btn size="sm" @click="resendInvite(row.item)" variant="primary">Send KYC</b-btn> -->
            </template>
          </b-table>
          <Spinner v-if="isFetchingTransactions === true"></Spinner>
        </div>
            <!-- </b-tabs> -->
      </div>
    </div>
    <b-modal
          id="showInviteMerchant"
          ref="showInviteMerchant"
          title=""
          @hide="hideInviteMerchantModal"
          no-close-on-backdrop
          header-class="invite-modal-header-custom"
          body-class="invite-modal-body-custom"
          footer-class="invite-modal-footer-custom"          
          hide-footer
          centered
          scrollable
      >
          <!-- Payment Types -->
          <div style="padding: 0px 30px 0px 30px;">
              <p style="font-size: 18px;color: rgb(0, 0, 0);margin-bottom: 3px;line-height: 24px;">Add/Invite Merchant</p>
              <div style="color: rgba(0, 0, 0, 0.5);font-size: 12px;font-weight: 325;line-height: 16px;">Manage the access of a merchant to the PortOne.</div>
              <div class="" style="border-top: 1px rgba(220, 218, 213, 0.30) solid;margin: 24px 0px;"></div>
          </div>

              <form id="modal" class="inviteMerchantModal" action="#" @submit.prevent="InviteMerchant">
                <!-- <div class="form-group font-size-12">
                  <div
                    class="btn btn-primary ml-2 px-1 py-1" id="managed-merchant-cont"
                    style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:115px;"
                  >
                    <div class="checkbox-group">
                      <input type="radio" id="MANAGED" value="MANAGED" v-model="AccountType">
                      <label for="MANAGED" class="position-relative mb-0"
                      :class="{
                          'filled': AccountType == 'MANAGED'
                        }">{{ $t('views.master_merchant.managed') }}</label>
                    </div>
                  </div>
                  <b-tooltip target="managed-merchant-cont">
                    <p>
                      {{
                        $t('views.master_merchant.managed_tooltip')
                      }}
                    </p>
                  </b-tooltip>
                   <div
                    class="btn btn-primary ml-2 px-1 py-1" id="owned-merchant-cont"
                    style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:115px;"
                  >
                    <div class="checkbox-group">
                      <input type="radio" id="OWNED" value="OWNED" v-model="AccountType">
                      <label for="OWNED" class="position-relative mb-0"
                      :class="{
                          'filled': AccountType == 'OWNED'
                        }">{{ $t('views.master_merchant.owned') }}</label>
                    </div>
                   </div>
                   <b-tooltip target="owned-merchant-cont">
                    <p>
                      {{
                        $t('views.master_merchant.owned_tooltip')
                      }}
                    </p>
                  </b-tooltip>
                </div> -->
                <div style="padding: 0px 30px;">
                <div
                  class="form-group"
                >
                  <label>Merchant Name</label>
                  <input
                    id="name"
                    v-model="merchant_name"
                    type="text"
                    class="form-control"
                    :placeholder="$t('views.master_merchant.placeholder.name')"
                    name="name"
                  />
                  <div class="error" style="font-size: 80%;" v-if="submitted && !$v.merchant_name.required"></div>
                </div>
                <div
                  class="form-group"
                >
                  <label>Email</label>
                  <input
                    id="email_id"
                    v-model="merchant_mail_id"
                    type="text"
                    class="form-control"
                    :placeholder="$t('views.master_merchant.placeholder.email')"
                    name="email_id"
                  />
                  <div class="error" style="font-size: 80%" v-if="submitted && !$v.merchant_mail_id.required">{{ $t('views.master_merchant.validation.name') }}</div>
                  <div class="error" style="font-size: 80%" v-if="submitted && !$v.merchant_mail_id.email">{{ $t('views.master_merchant.validation.email') }}</div>
                </div>
                <div class="form-group" style="margin-bottom: 32px;">
                  <label for="country">Country</label>

                  <b-form-select name="country" v-model="merchant_country" class="form-control">

                    <b-form-select-option v-for="country in countryList" :key="country" :value="country">{{ getRegionName(country) }}</b-form-select-option>
                  </b-form-select>

                </div>
                <div @click="toggleOptions" style="margin-bottom: 24px; cursor: pointer; align-items: center;" class="d-flex">
                  <label class="text-chai" style="cursor: pointer;margin-bottom: 0px;">Advanced Options</label>
                  <span style="padding-left: 10px;">
                    <svg v-show="showOptions" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M11.62 8.7793L7.81667 4.97596C7.3675 4.5268 6.6325 4.5268 6.18334 4.97596L2.38 8.7793" stroke="#FC6B2D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg v-show="!showOptions" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M11.6199 5.2207L7.81655 9.02404C7.36738 9.4732 6.63238 9.4732 6.18322 9.02404L2.37988 5.2207" stroke="#FC6B2D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                </div>
                <div v-show="showOptions">
                    <div class="d-flex" style="margin-bottom: 16px; background: rgba(220, 218, 213, 0.10);border-radius: 12px;border: 1px #DCDAD5 solid;padding: 10px;justify-content: space-between;align-items: center;">
                      <div class="d-flex">
                        <div style="background: rgba(220, 218, 213, 0.50);border-radius: 8px;height: 40px;width: 40px;padding: 10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M14.1676 18.334H5.83427C2.50094 18.334 1.6676 17.5007 1.6676 14.1673V12.5007C1.6676 9.16732 2.50094 8.33398 5.83427 8.33398H14.1676C17.5009 8.33398 18.3343 9.16732 18.3343 12.5007V14.1673C18.3343 17.5007 17.5009 18.334 14.1676 18.334Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 8.33268V6.66602C5 3.90768 5.83333 1.66602 10 1.66602C13.75 1.66602 15 3.33268 15 5.83268" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.0009 15.4167C11.1515 15.4167 12.0843 14.4839 12.0843 13.3333C12.0843 12.1827 11.1515 11.25 10.0009 11.25C8.85034 11.25 7.9176 12.1827 7.9176 13.3333C7.9176 14.4839 8.85034 15.4167 10.0009 15.4167Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div style="padding-left: 12px;">
                          <p style="font-size: 14px;line-height: 20px;margin-bottom: 0px;">Login Access</p>
                          <p style="color: rgba(0, 0, 0, 0.50);font-size: 12px;line-height: 16px;font-weight: 325;margin-bottom: 0px;">Allow login access to the sub-merchant</p>
                        </div>
                      </div>
                      <div>
                          <switch-input class="my-auto" v-model="isLoginAllowed" :value="isLoginAllowed" />
                      </div>
                    </div>
                  <!-- <div class="my-3">
                    <div
                      class="btn btn-primary ml-1 px-1 py-1" id="invite-merchant"
                      style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:130px;"
                    >
                      <div class="checkbox-group">
                        <input type="radio" id="INVITE_MERCHANT" v-model="isInviteMerchant" value="true" :checked="isInviteMerchant" @click="() => isInviteMerchant = !isInviteMerchant">
                        <label for="INVITE_MERCHANT" class="position-relative mb-0"
                        :class="{
                            'filled': isInviteMerchant
                          }">Invite Merchant</label>
                      </div>
                    </div>
                  </div> -->
                  <div class="d-flex" style="margin-bottom: 16px; background: rgba(220, 218, 213, 0.10);border-radius: 12px;border: 1px #DCDAD5 solid;padding: 10px;justify-content: space-between;align-items: center;">
                    <div class="d-flex">
                      <div style="background: rgba(220, 218, 213, 0.50);border-radius: 8px;height: 40px;width: 40px;padding: 10px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M9.18337 16.25H6.25004C5.73337 16.25 5.27504 16.2333 4.86671 16.175C2.67504 15.9333 2.08337 14.9 2.08337 12.0833V7.91667C2.08337 5.1 2.67504 4.06667 4.86671 3.825C5.27504 3.76667 5.73337 3.75 6.25004 3.75H9.13337" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M12.5167 3.75H13.7501C14.2667 3.75 14.7251 3.76667 15.1334 3.825C17.3251 4.06667 17.9167 5.1 17.9167 7.91667V12.0833C17.9167 14.9 17.3251 15.9333 15.1334 16.175C14.7251 16.2333 14.2667 16.25 13.7501 16.25H12.5167" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M12.5 1.66602V18.3327" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M9.24534 10.0007H9.25283" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M5.91209 10.0007H5.91957" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                      <div style="padding-left: 12px;">
                        <p style="font-size: 14px;line-height: 20px;margin-bottom: 0px;">Use Master Merchant Credentials</p>
                        <p style="color: rgba(0, 0, 0, 0.50);font-size: 12px;line-height: 16px;font-weight: 325;margin-bottom: 0px;">Set your payment channel credentials to be used by the sub-merchant</p>
                      </div>
                    </div>
                    <div>
                        <switch-input class="my-auto" v-model="useMasterMerchantCreds" :value="useMasterMerchantCreds" />
                    </div>
                  </div>

                  <div class="d-flex" style="background: rgba(220, 218, 213, 0.10);border-radius: 12px;border: 1px #DCDAD5 solid;padding: 10px;justify-content: space-between;align-items: center;">
                      <div class="d-flex">
                        <div style="background: rgba(220, 218, 213, 0.50);border-radius: 8px;height: 40px;width: 40px;padding: 10px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M1.66663 7.08268C1.66663 4.16602 3.33329 2.91602 5.83329 2.91602H14.1666C16.6666 2.91602 18.3333 4.16602 18.3333 7.08268V12.916C18.3333 15.8327 16.6666 17.0827 14.1666 17.0827H5.83329" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.1667 7.5L11.5584 9.58333C10.7 10.2667 9.2917 10.2667 8.43337 9.58333L5.83337 7.5" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.66663 13.75H6.66663" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.66663 10.416H4.16663" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <div style="padding-left: 12px;">
                          <p style="font-size: 14px;line-height: 20px;margin-bottom: 0px;">Send KYC Email</p>
                          <p style="color: rgba(0, 0, 0, 0.50);font-size: 12px;line-height: 16px;font-weight: 325;margin-bottom: 0px;">Send an email with the KYC Link to the sub-merchant.</p>
                        </div>
                      </div>
                      <div>
                          <switch-input class="my-auto" v-model="sendKycLinkEmail" :value="sendKycLinkEmail" />
                      </div>
                    </div>
                </div>
                </div>
                <!-- <div
                  class="form-group"
                >
                  <input
                    id="phone_number"
                    v-model="merchant_phone_number"
                    type="text"
                    class="form-control"
                    :placeholder="`Enter Phone Number`"
                    name="phone_number"
                  />
                </div> -->
                <!-- <div class="form-group text-right mt-4 font-size-12">
                  <span >Account Status</span>
                   <div
                    class="btn btn-primary ml-2 px-1 py-1"
                    style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:95px;"
                  >
                    <div class="checkbox-group">
                      <input type="checkbox" id="filter_vietnam" value="true" v-model="AccountStatus">
                      <label for="filter_vietnam" class="position-relative mb-0"
                      :class="{
                          'filled': AccountStatus
                        }">Activate</label>
                    </div>
                   </div>
                </div> -->
                <div class="d-flex justify-content-end" style="padding: 24px 30px;box-shadow: 0px -4px 10px rgba(220, 218, 213, 0.30);margin-top: 24px;">
                    <div>
                      <b-btn variant="primary" @click="hideInviteMerchantModal" style="padding: 8px 16px;border-radius: 8px;background-color: rgba(220, 218, 213, 0.30);border: none;line-height: 20px;" class="mr-2 cancel text-dark">Cancel</b-btn>
                      <b-btn variant="primary" type="submit" class="btn-padding text-chai" style="padding: 8px 16px;border-radius: 8px;background-color: rgba(220, 218, 213, 0.3);border: none;line-height: 20px;" :disabled="!isValid">Add</b-btn>
                    </div>
                  </div>
                <!-- <div class="mt-4">
                  <div style="font-size: 13px;">Owned Merchant will be added immediately.</div>
                  <div style="font-size: 13px;">Managed Merchant will be invited.</div>
                </div> -->
              </form>
      </b-modal>
        <b-modal
            id="delete-merchant-modal"
            ref="delete-merchant-modal"
            title=""
            hide-footer
            no-close-on-backdrop
            header-class="modal-header-custom-merchant-delete"
            body-class="modal-body-custom-merchant-delete"
            footer-class="modal-footer-custom-merchant-delete"
            centered
        >
            <div>
                <div class="text-black" style="font-size: 24px;margin-bottom: 24px;line-height: 24px;">Delete Merchant</div>
                <div style="color: rgba(0, 0, 0, 0.70);font-size: 14px;line-height: 20px;margin-bottom: 16px;">Are you sure you want to delete this merchant permanently?</div>
                <div style="padding: 16px; border-radius: 12px;border: 2px rgba(220, 218, 213, 0.30) solid;display: flex; margin-bottom: 16px; align-items: center;">
                    <div v-if="deteleMerchantData.name" style="font-size: 12px;color: rgba(0, 0, 0, 0.70);text-transform: uppercase;background: rgba(252.13, 102.24, 45.72, 0.20);border-radius: 50%;margin-right: 16px;width: 40px;height: 40px;line-height: 40px;text-align: center;">
                      {{ getFirstLetter(deteleMerchantData.name) }}
                    </div>
                    <div>
                        <div style="font-size: 14px;line-height: 20px;">
                            {{deteleMerchantData.name}}
                        </div>
                        <div class="text-chai" style="font-size: 12px;color: rgba(0, 0, 0, 0.50);line-height: 16px;">
                            {{ deteleMerchantData.email_address }}
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="gap: 10px;justify-content: flex-end;">
                    <b-btn variant="primary" @click="$refs['delete-merchant-modal'].hide();" style="padding: 12px 16px;" class="bg-white text-dark">Cancel</b-btn>
                    <b-btn variant="primary" style="padding: 12px 23px;" @click="deleteMerchant(deteleMerchantData)">Yes, Delete This Merchant!</b-btn>
                </div>
            </div>
        </b-modal>

    <div
      class="right-bar"
      :class="{
        active: viewDetailsModalOpen,
      }"
    >
      <div data-simplebar class="h-100" style="overflow-x: auto">
        <div class="rightbar-title">
          <div class="right-top-section">
            <div class="card-body-custom">
              <i
                class="mdi mdi-close noti-icon float-right cursor-pointer"
                style="
                  position: relative;
                  z-index: 1;
                  right: 25px;
                  top: 25px;
                  font-size: 20px;
                "
                @click="closeViewDetailsModal"
              ></i>
              <div style="padding-left: 30px; padding-bottom: 25px; padding-right: 30px;" class="media-body media-body-custom">
                <p
                  class="d-inline-block"
                  style="font-size: 18px; margin-top: 45px; margin-bottom: 0px; line-height: 24px;"
                >
                  Merchant Details
                </p>
                <div style="color: rgba(0, 0, 0, 0.50);font-size: 12px;font-weight: 325;line-height: 16px;margin-top: 2px;">Check details of the selected merchant.</div>
              </div>
              <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px;"></div>
            </div>
          </div>
        </div>
        <div class="plan-view-details" style="padding-left: 30px;padding-right: 30px;">
          <!-- {{viewDetailsData}} -->
          <!-- <div class="plan-view-section">
            <div class="plan-view-heading">Plan ID</div>
            <div class="plan-view-value text-chai">{{viewDetailsData.order_ref}}</div>
          </div> -->
          <div class="plan-view-section">
            <div class="plan-view-heading">Merchant Name</div>
            <div class="plan-view-value">{{viewDetailsData.name}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Merchant Email</div>
            <div class="plan-view-value text-chai">{{viewDetailsData.email_address}}</div>
          </div>
          <!-- <div class="plan-view-section">
            <div class="plan-view-heading">Country</div>
            <div class="plan-view-value">{{viewDetailsData.plan_type == 'ONDEMAND' ? 'As Per Request' : viewDetailsData.amount}}</div>
          </div> -->
           <div class="plan-view-section">
            <div class="plan-view-heading">Account Status</div>
            <div class="plan-view-value">
              <!-- <switch-input class="ml-auto" disableToggle :value="viewDetailsData.is_active" /> -->
              <div
                class="badge font-size-12"
                style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                :class="{
                  'badge-soft-inactive':`${viewDetailsData.status}` === 'INACTIVE',
                  'badge-soft-success': `${viewDetailsData.status}` === 'ACTIVE',
                }"
              >
                {{ viewDetailsData.status }}
              </div>
              <label  v-if="viewDetailsData.status == 'ACTIVE'" style="margin-left: 12px;font-size: 12px;text-decoration: underline;" @click="markasActiveandInactive('INACTIVE',viewDetailsData.key)" class="text-chai mb-0 cursor-pointer">Disable account access</label>
              <label  v-if="viewDetailsData.status == 'INACTIVE'" @click="markasActiveandInactive('ACTIVE',viewDetailsData.key)" style="margin-left: 12px;font-size: 12px;text-decoration: underline;" class="text-chai mb-0 cursor-pointer">Activate account access</label>
            </div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Advanced Options</div>
            <div class="plan-view-value" style="display: flex;align-items: center;justify-content: space-between;">
              <span >Allow login to the merchant account</span>
              <switch-input class="ml-auto" disableToggle :value="viewDetailsData.is_login_allowed" />
            </div>
            <div class="plan-view-value" style="padding-top:15px;display: flex;align-items: center;justify-content: space-between;">
              <span >Use Master Merchant Credentials</span>
              <switch-input class="ml-auto" disableToggle :value="viewDetailsData.use_master_merchant_creds" />
            </div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">KYC Status</div>
            <div class="plan-view-value">
              <div
                class="badge font-size-12"
                style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                :class="{
                    'badge-soft-danger':`${viewDetailsData.kyc_status}` === 'PENDING',
                    'badge-soft-warning': `${viewDetailsData.kyc_status}` === 'SUBMITTED',
                    'badge-soft-success': `${viewDetailsData.kyc_status}` === 'DONE',
                }"
                >
                {{ viewDetailsData.kyc_status }}
              </div>
            </div>
          </div>

          <div class="plan-view-section">
            <div class="plan-view-heading">KYC Link</div>
            <div class="plan-view-value">
              <div class="d-flex" style="justify-content: space-between;align-items: center;">
                <p class="mb-0" style="width: calc(100% - 50px);">
                  <a :href="viewDetailsData.kyc_link"  class="text-chai" target="_blank">{{ viewDetailsData.kyc_link }}</a></p>
                <span class="ml-2" 
                    v-show="viewDetailsData.kyc_link"
                    v-clipboard:copy="viewDetailsData.kyc_link"
                    v-clipboard:success="onCopySuccess"
                    v-b-tooltip.hover.right
                    :title="$t('common.copy')"
                    style="cursor: pointer; width: 40px;height: 40px;background: rgba(245, 244, 243, 1);border-radius: 8px;position: relative;">
                  <svg style="position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g opacity="0.7" clip-path="url(#clip0_1540_47398)">
                      <path d="M12 9.675V12.825C12 15.45 10.95 16.5 8.325 16.5H5.175C2.55 16.5 1.5 15.45 1.5 12.825V9.675C1.5 7.05 2.55 6 5.175 6H8.325C10.95 6 12 7.05 12 9.675Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16.5 5.175V8.325C16.5 10.95 15.45 12 12.825 12H12V9.675C12 7.05 10.95 6 8.325 6H6V5.175C6 2.55 7.05 1.5 9.675 1.5H12.825C15.45 1.5 16.5 2.55 16.5 5.175Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1540_47398">
                        <rect width="18" height="18" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
               <div style="margin-top: 16px;">
                <span v-if="!['SUBMITTED', 'DONE'].includes(viewDetailsData.kyc_status)">
                  <b-btn style="border-radius: 8px;" size="sm" v-if="viewDetailsData.send_kyc_link_email" @click="resendKYC(viewDetailsData)" variant="primary">{{ $t('views.master_merchant.resend_kyc') }}</b-btn>
                  <b-btn style="border-radius: 8px;" size="sm" v-else @click="resendKYC(viewDetailsData)" variant="primary">{{ $t('views.master_merchant.send_kyc') }}</b-btn>
                </span>
              </div>
            </div>
          </div>

          <!-- <div class="plan-view-section">
            <div class="plan-view-heading">Plan Description</div>
            <div class="plan-view-value">{{viewDetailsData.description}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Custom Fields</div>
            <div v-if="viewDetailsData.notes">
              <div class="plan-view-value d-flex" style="gap: 20px;" v-for="(note, index) in viewDetailsData.notes.notes" :key="index">
                <span>{{ note.key }}</span>
                <span>{{ note.value }}</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- end slimscroll-menu-->
    </div>
    <!-- Right bar overlay-->
    <div
      @click="closeViewDetailsModal"
      class="rightbar-overlay"
      :class="{
        active: viewDetailsModalOpen,
      }"
    ></div>

    <!-- <div> -->
        <!-- Right Sidebar -->
        <!-- <div class="right-bar"
        :class="{
            'active': showInviteMerchantmodal
          }">
          <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt />
          <div data-simplebar class="h-100">
            <div class="rightbar-title pb-3">
              <div class="right-top-section">
                <img src="@/assets/images/update_config_page.png" class="right-top-bg-image" alt />
                <div class="card-body-custom">
                  <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hideInviteMerchantModal"></i>
                  <div class="media-body media-body-custom d-inline-block">
                    <p class="d-inline-block mb-0 mt-4 font-size-15">{{ $t('views.master_merchant.invite_merchant') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3">

            </div>
          </div> -->
          <!-- end slimscroll-menu-->
        <!-- </div> -->
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <!-- <div @click="hideInviteMerchantModal" class="rightbar-overlay"
        :class="{
            'active': showInviteMerchantmodal
        }"></div>
        <div v-if="isInviteSend === true" style="background-color: rgba(52, 58, 64, 0.55);position: fixed;left: 0;right: 0;top: 0;bottom: 0;z-index: 9998;transition: all 0.2s ease-out;">
          <div style="position: absolute;top: 50%;left: 50%;transform: translateY(-50%);">
            <Spinner></Spinner>
          </div>
        </div> 
      </div> -->
  </Layout>
</template>
<style>
.invite-modal-header-custom,.modal-header-custom-merchant-delete{
    border-bottom: none;
    padding-bottom: 0px;
}
.invite-modal-body-custom{
    padding: 0px;
}
.modal-body-custom-merchant-delete{
  padding: 0px 30px 30px 30px;
}
.invite-modal-footer-custom{
    border-top: none;
    padding: 0px 35px 35px 35px;
}
.inviteMerchantModal label{
    color: black;
    font-size: 14px;
    margin-bottom: 10px;
}
.inviteMerchantModal .form-group{
    margin-bottom: 20px;
}
.inviteMerchantModal select, .inviteMerchantModal input, .inviteMerchantModal textarea{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    color: #000;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    overflow: hidden;
    border: 1px #DCDAD5 solid;
    height: 44px;
}
</style>
<style lang="css" scoped>
::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}
.media-body-custom{
  font-size: 12px;
  position: relative;
}
.media-body-custom img{
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 10px;
  bottom:2px;
}
.btn-custom{
  border-radius: 4px;
  color: #FFFCFC;
  background-color: #252b3a;
  border-color: #252b3a;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-custom.disabled{
  pointer-events: none;
}
.search-button{
  background: #EAEAEA;
  border-color: #EAEAEA;
  color: #B3AFAF;
}
::v-deep .main-content {
    overflow: visible;
}
.card-body-custom{
  padding:15px 20px;
  position: relative;
}
.dark-bg{
  background-color: #252B3A;
  border-color: #252B3A;
}
.cursor-pointer{
  cursor: pointer;
}
.checkbox-group{
  position: relative;
}
.checkbox-group input{
  display: none;
}
.checkbox-group  label {
  cursor: pointer;
}
.checkbox-group{
  display: inline-block;
}
.checkbox-group  label {
  cursor: pointer;
}
.checkbox-group  label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #DAD9D9;
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  bottom:1px;
}
.checkbox-group  label.filled:before {
  background-color: #252B3A;
  border: 1px solid #252B3A;
}

.checkbox-group label.filled:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 4px;
  height: 9px;
  border: 1px solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
::v-deep .custom-datepicker .xmx-input{
  height: 44px !important;
  padding-top: 4px;
  min-height: unset;
  border: 1px solid #DCDAD5;
  box-shadow: none;
  border: 1px solid #DCDAD5;
  border-radius: 12px;
}
.badge-soft-success{
    background: rgba(33, 150, 83, 0.10);
    color: #219653;
}
.badge-soft-danger{
    background: rgba(222, 53, 53, 0.10);
    color: #DE3535;
}
.badge-soft-inactive{
  background: rgba(220, 218, 213, 0.50);
  color: rgba(0, 0, 0, 0.70)
}
::v-deep .toggle-in-table .slider {
  width: 32px;
  height: 18px;
}
::v-deep .toggle-in-table .slider:before {
  height: 14px;
  width: 14px;
}
::v-deep .toggle-in-table input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}
::v-deep  .show_details button{
    padding: 7px 9px;
    border-radius: 8px;
    background: rgba(220, 218, 213, 0.30);
    height: 36px;
}
::v-deep  .show_details ul{
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    border-radius: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    transform: translate3d(-138px,40px,0)!important;
    z-index: 1!important;
}
::v-deep  .show_details ul button{
    padding: 12px 25px;
    border-radius: 0px;
    background-color: transparent;
    height: auto;
}

.right-top-section{
  position: relative;
  z-index: 1;
}
.right-bar{
  width: 500px;
  right: -500px;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
  border: 1px solid #00246414;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}

.cursor-pointer{
  cursor: pointer;
}
@media (max-width: 700px) {
  .right-bar{
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
.plan-view-section{
  margin-bottom: 16px;
}
.plan-view-section .plan-view-heading{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.30);
    line-height: 20px;
}
.plan-view-section .plan-view-value{
    font-size: 14px;
    line-height: 20px;
    padding-top: 4px;
}

</style>